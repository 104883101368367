import tippy from 'tippy.js'

(() => {
  window.tippy = tippy

  const activateListingTermsPopups = () => {
    window.tippy('[data-terms] button', {
      content(reference) {
        const textWrapper = reference.parentElement.parentElement.querySelector('.terms-content')
        return textWrapper ? textWrapper.innerText : ''
      },
      placement: 'top',
      interactive: true,
      arrow: true,
      allowHTML: true
    })
  }

  activateListingTermsPopups()

  window.activateListingTermsPopups = activateListingTermsPopups
})()
