export const Filters = {
  get: (wrapper) => JSON.parse(wrapper.dataset.filters || null) || {},
  set: (wrapper, filters) => {
    wrapper.dataset.filters = filters ? JSON.stringify(filters) : ''
  },
  update: (wrapper, group, key) => {
    const filters = Filters.get(wrapper)
    if (group && key) {
      filters[group] = filters[group] === key ? '' : key
    }
    Filters.set(wrapper, filters)
    return filters
  },
  reset: (wrapper) => {
    Filters.set(wrapper, '')
    wrapper.querySelectorAll('label').forEach(label => Filters.Checkbox.off(label))
  },

  Checkbox: {
    on: (el) => el.classList.add('selected'),
    off: (el) => el.classList.remove('selected')
  }
}
