export const Sorting = {
  get: (wrapper) => JSON.parse(wrapper.dataset.sorting || null) || {},
  set: (wrapper, sorting) => {
    wrapper.dataset.sorting = sorting ? JSON.stringify(sorting) : ''
  },
  update: (wrapper, key, order) => {
    let sorting
    const sortValues = Sorting.get(wrapper)

    if (Object.keys(sortValues).length && key === sortValues.key) {
      sortValues.order = sortValues.order === 'DESC' ? 'ASC' : 'DESC'
      sorting = sortValues
    } else {
      sorting = { key, order }
    }
    Sorting.set(wrapper, sorting)
    return sorting
  },
  reset: (wrapper) => {
    Sorting.set(wrapper, '')
    wrapper.querySelectorAll('button[data-sort-order]').forEach(btn => Sorting.Button.reset(btn))
  },

  Button: {
    key: (btn) => btn.dataset.sortKey,
    order: (btn) => {
      let currentOrder = btn.dataset.sortOrder
      if (currentOrder === 'NONE') currentOrder = 'ASC'
      const order = currentOrder === 'DESC' ? 'ASC' : 'DESC'

      btn.dataset.sortOrder = order
      return order
    },
    reset: (btn) => {
      btn.dataset.sortOrder = 'NONE'
    }
  }
}
