import { Tools } from './tools'
import { Filters } from './filters'
import { Sorting } from './sorting'
import { Limiting } from './limiting'
import { updateListing } from './listing'

(() => {
  document.addEventListener('click', async (e) => {
    // open tools popup
    if (e.target.classList.contains(Tools.popupOpenButtonClass)) {
      const wrapper = document.querySelector(`.${Tools.wrapperClass}[data-element-id="${e.target.dataset.elementId}"]`)
      const popup = Tools.Popup.get(wrapper)
      Tools.Popup.open(popup)
    }

    // close tools popup (apply button)
    if (e.target.classList.contains(Tools.popupApplyButtonClass)) {
      const wrapper = Tools.Wrapper(e.target)
      const popup = Tools.Popup.get(wrapper)
      if (popup) Tools.Popup.close(popup)
    }

    // close tools popup (click outside)
    if (e.target.classList.contains(Tools.popupClass)) {
      Tools.Popup.close(e.target)
    }

    // filter checkbox click/check event
    if (e.target.classList.contains(Tools.filtersCheckboxClass)) {
      const ch = e.target
      const key = ch.dataset.value
      const group = ch.dataset.group

      const selected = ch.closest('label').classList.contains('selected')
      ch.closest('ul').querySelectorAll('label').forEach(label => Filters.Checkbox.off(label))
      if (!selected) {
        Filters.Checkbox.on(ch.closest('label'))
      }

      const wrapper = Tools.Wrapper(ch)
      const filters = Filters.update(wrapper, group, key)
      const sorting = Sorting.get(wrapper)
      const limiting = Limiting.reset(wrapper)

      await updateListing(filters, sorting, limiting, ch, 'filters')

      const loaded = wrapper.closest('.sb-listing-wrapper').querySelectorAll('.sb-item')
      wrapper.dataset.allLoaded = wrapper.dataset.total > loaded.length ? 'false' : 'true'
    }

    // sorting button click event
    if (e.target.dataset.sortKey) {
      const button = e.target

      button.closest('ul').querySelectorAll('button').forEach(btn => {
        if (btn !== button) Sorting.Button.reset(btn)
      })

      const wrapper = Tools.Wrapper(button)
      const key = Sorting.Button.key(button)
      const order = Sorting.Button.order(button)
      const sorting = Sorting.update(wrapper, key, order)
      const filters = Filters.get(wrapper)
      const limiting = Limiting.get(wrapper)

      await updateListing(filters, sorting, limiting, button, 'sorting')
    }

    // load more button click event
    if (e.target.closest(`.${Tools.limitingWrapperClass}`)) {
      const el = e.target.closest(`.${Tools.limitingWrapperClass}`)
      const wrapper = Tools.Wrapper(el)

      // if the source is manual
      if (wrapper.dataset.source === 'manual') {
        Limiting.Manual.showHiddenItems(wrapper)
        if (Limiting.Manual.getHiddenItems(wrapper).length === 0) {
          wrapper.dataset.allLoaded = 'true'
        }
        // if the source is not manual
      } else {
        const filters = Filters.get(wrapper)
        const sorting = Sorting.get(wrapper)
        const limiting = Limiting.update(wrapper)
        await updateListing(filters, sorting, limiting, el, 'limiting')

        const loaded = wrapper.closest('.sb-listing-wrapper').querySelectorAll('.sb-item')
        wrapper.dataset.allLoaded = wrapper.dataset.total > loaded.length ? 'false' : 'true'
      }

      window.activateListingTermsPopups()
    }

    // reset filters and sorting button click event
    if (e.target.classList.contains(Tools.popupResetButtonClass)) {
      const button = e.target
      const wrapper = Tools.Wrapper(button)

      Filters.reset(wrapper)
      Sorting.reset(wrapper)
      const limiting = Limiting.reset(wrapper)

      await updateListing({}, {}, limiting, button, 'reset')

      wrapper.dataset.allLoaded = 'false'
    }
  })

  document.addEventListener('keyup', (e) => {
    // filter box search inputs
    const input = e.target
    if (input.dataset.filterSearch) {
      if (e.key === 'Escape') {
        input.value = ''
      }
      const labels = input.closest(`.${Tools.filtersBlockClass}`).querySelectorAll('label')
      labels.forEach(label => {
        const item = label.innerText
        label.style.display = !item.toLowerCase().includes(input.value.toLowerCase())
          ? 'none'
          : 'flex'
      })
    }
  })
})()
