export const Tools = {
  wrapperClass: 'sb-listing-tools',
  popupClass: 'sb-listing-popup',
  popupOpenButtonClass: 'sb-listing-popup--button',
  popupApplyButtonClass: 'sb-listing-popup--apply',
  popupResetButtonClass: 'sb-listing-popup--reset',
  filtersBlockClass: 'sb-listing-filters-block',
  filtersCheckboxClass: 'sb-listing-filters--checkbox',
  limitingWrapperClass: 'sb-listing-limiting',

  Listing: (id) => document.querySelector(`.sb-listing[data-element-id="${id}"]`),

  Wrapper: (el) => el.closest(`.${Tools.wrapperClass}`),

  Popup: {
    get: (wrapper) => wrapper.querySelector(`.${Tools.popupClass}`),
    open: (popup) => {
      popup.style.display = 'block'
    },
    close: (popup) => {
      popup.style.display = 'none'
    }
  }
}
