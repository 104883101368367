import { Tools } from './tools'
import { kemokuFetch } from '../../utils'

export const updateListing = async (filters, sorting, limiting, el, method) => {
  const params = new URLSearchParams()
  params.append('action', 'modify-listing')
  params.append('filters', JSON.stringify(filters))
  params.append('sorting', JSON.stringify(sorting))
  params.append('limiting', JSON.stringify(limiting))
  params.append('query', el.closest('.sb-listing-wrapper').dataset.atts || '')
  params.append('method', method)

  const result = await kemokuFetch(params)

  const wrapper = Tools.Wrapper(el)
  const listing = Tools.Listing(wrapper.dataset.elementId)
  listing.innerHTML = method !== 'limiting' ? result.html : `${listing.innerHTML}${result.html}`
  wrapper.dataset.total = result.count
}
